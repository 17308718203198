@import "../../custom.scss";

$title-color: #fff;
$description-color: rgba(255, 255, 255, .5);

.stepper-div {
    min-height: 5rem;
    padding: 2rem 5rem 2rem 5rem;
    background-color: $primary;
    .rc-steps {
        .rc-steps-item-title {
            color: $title-color;
        }
        .rc-steps-item-description {
            color: $title-color;
            max-width: 180px !important;
        }
        color: $title-color;
        .rc-steps-item-finish {
            .rc-steps-item-title {
                color: $title-color;
            }
        }
        .rc-steps-item-wait {
            .rc-steps-item-title {
                color: rgba(255, 255, 255, .5);
            }
            .rc-steps-item-description {
                color: $description-color;
            }
        }
    }
}