@import "../../custom.scss";

.carousel {
  margin: 0 auto;
  max-width: $width-container-size;
  .slider-container {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100%;
      max-height: 50%;
      .slide {
        margin-bottom: 1rem;
      }
    }
    &:hover {
      .controller {
        visibility: visible;
        opacity: .8;
      }
    }
  
    .controller {
      border-radius: 50%;
      visibility: hidden;
      background-color: lighten($primary, 25);
      border: 0;
      height: 40px;
      width: 40px;
      position: absolute;
      margin: auto 0;
      opacity: 0;
      transition: opacity .5s ease;
      &.back {
        color: #fff;
        left: 20px;
        top: 2rem;
        bottom: 0;
      }
      &.next {
        color: #fff;
        right: 20px;
        top: 2rem;
        bottom: 0;
      }
      &:hover {
        opacity: 1;
      }
      &:focus {
        outline-width: 0;
      }
    }
}