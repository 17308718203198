@import '../../custom.scss';

.main-footer {
	.links-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	font-family: Montserrat;
	.site-name {
		color: #DCE1EA;
	}
	color: #fff;
	padding: 2rem 0 2rem 0 !important;
	background-color: $primary !important;
	.row {
		margin-left: 0px;
		margin-right: 0px;
		.col-* {
			display: flex;
			align-content: center;
			justify-content: center;
		}
		.text-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 2rem;
			margin-bottom: 1rem;
			a {
				margin: 0 0 0 1rem;
			}
			p {
				margin-bottom: 0;
				font-size: 20px;
				color: #FFFFFF;
				width: 177px;
				font-weight: 800;
				text-align: left;
			}
		}
		.image-container{
			display: block;
			width: 100%;
			max-height: 4rem;
			svg {
				margin: 0 auto !important;
				width: 100% !important;
				max-height: inherit;
			}
		}
	}
}
.sub-footer {
    text-align: center;
	padding: 1rem 0 1rem 0 !important;
	color: rgba(255,255,255,.8);
	a:hover, a {
		color: rgba(255,255,255,.8);
	}
	background-color: $secondary !important;
}



@include media-breakpoint-down(sm) {
	.main-footer {
		.image-container {
			width: 100%;
			h1 {
				text-align: center;
			}
		}
		.text-container {
			margin: 0 !important;
			.links-container {
				width: 100%;
				justify-content: space-evenly;
			}
			flex-direction: column;
			p {
				text-align: center !important;
				width: 100%;
			}
			a {
				margin: 0 !important;
			}
		}
	}
}

@include media-breakpoint-up(sm) {

  }
@include media-breakpoint-up(md) {
}