@import '../../custom.scss';

.contacts {
    position: relative;
    h2 {
        color: #fff !important;
    }
    h3 {
        color: #fff !important;
    }
    .callout {
        background-color: $secondary;
        min-height: 30vh;
        color: #fff;
        padding-top: 2rem;
    }
    .filler {
        width: 100%;
        min-height: 60vh;
    }
    .card {
        border-radius: 10px;
        max-width: 1000px;
        &.contact-box {
            background-clip: padding-box;
            overflow: hidden;
            left: 0;
            right: 0;
            margin: 0 auto;
            .card-body {
                position: relative;
                color: #fff;
                z-index: 1;
                display: flex;
                align-items: center;
                height: 100%;
            }
            .form-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                .form-group {
                    width: 100%;
                }
            }
            .row {
                height: 100%;
                backface-visibility: hidden;
                .col-md-6 {
                    overflow: hidden;
                }
                .card-bg {
                    z-index: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover !important;
                    background-position: center !important;
                    filter: brightness(30%);
                    transform: translateZ(0) scale(1.2) translate3d(0,0,0);
                }
            }
        }
    }
}



@include media-breakpoint-down(sm) {
    .contacts {
        .callout {
            min-height: 15vh;
            h1 {
                margin-bottom: 0;
            }
        }
        .filler {
            min-height: 100vh;
            margin-bottom: 2rem;
        }
        .col-12 {
            padding: 0;
        }
        .card {
            border: 0;
            border-radius: 0;
            &.contact-box {
                position: relative;
                min-height: 100vh;
                width: 100%;
                form {
                    margin: 1rem 0;
                    padding: 0 2rem ;
                }
            }
        }
    }
  }
  
  @include media-breakpoint-up(sm) {
    .contacts {
        .card {
            &.contact-box {
                width: 80%;
                top: 8rem;
                position: absolute;
                height: 70vh;
            }
        }
    }
  }
  
  @include media-breakpoint-up(lg) {
    .contacts {
        .card {
            &.contact-box {
                width: 70%;
                top: 8rem;
                position: absolute;
                height: 70vh;
            }
        }
    }
  }