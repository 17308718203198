
.modal-wrapper.is-active {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    justify-content: center;
    align-items: center;
    .modal-background {
        position: absolute;
        background-color: rgba($color: #000000, $alpha: .5);
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
    }
    .modal {
        border: none !important;
        display: flex;
        position: absolute;
        width: auto;
        height: auto;
        top: auto;
        left: auto;
        .modal-content {
            border-radius: 10px;
            border: none !important;
        }
    }
}