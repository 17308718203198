@import '../../custom.scss';

// NOTE: the order of these styles DO matter
$light-red: red;
$dark-red: darkred;
// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: $primary; //background
  color: white; //text
  border: 1px solid $primary; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $secondary;
  color: white;
  border: 1px solid $secondary;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: blue;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $primary;
  color: white;
}

.DateInput {
    width: 45%;
}
.form-inline {
  .DateRangePicker {
    max-width: 300px;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  button.inline {
    margin-left: 1rem;
    border-radius: 5rem;
    height: 3rem;
    width: 3rem;
  }

}
.DateRangePickerInput_arrow {
    width: 10%;
}

.timepicker-wrapper {
  border: 1px solid #dbdbdb;
  border-top: 0;
  background-color: #fff;
  &.inline {
    border: 1px solid #dbdbdb;
    height: 3rem;
    margin-left: 10px;
  }
  .timepicker-icon {
    width: 10%;
    display: inline-block;
  }
  .search-timer {
    width: 45%;
    .react-time-picker__wrapper {
      .react-time-picker__inputGroup__input {
        color: #484848;
      }
      border: 0;
      border-top: 0;
      padding: 11px 11px 9px;
    }
  }
}
@include media-breakpoint-down(sm) {
  .clearfix {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .search-button {
    display: block;
  }
  .form-inline {
    justify-content: flex-end;
    .DateRangePicker {
      max-width: none;
    }
  }
  .timepicker-wrapper {
    &.inline {
      width: 100%;
      margin-left: 0;
    }
  }
}
