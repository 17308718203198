@import '../../custom.scss';


select.form-control {
    &.inline {
        border: 1px solid #dbdbdb;
        height: 3rem;
        margin-right: 10px;
    }
}

@include media-breakpoint-down(sm) {
    .form-inline {
        .form-group {
            width: 100%;
        }
    }
    select.form-control {
        &.inline {
            border: 1px solid #dbdbdb;
            height: 3rem;
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }  
}