.vehicle-card {
    max-width: 20rem;
    margin: 0 auto;
    background-color: transparent;
    transition: background-color .5s ease;
    .card-title {
        margin-bottom: 0;
    }
    .prices {
        margin-top: .5rem;
        .total {
            color: grey;
        }
    }
    border: 0 !important;
    img {
        border-radius: 1.5rem;
        border: 1px solid rgb(231, 231, 231);
    }
    a {
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 0;
    }
    h2.subtitle {
        color: grey;
        font-size: 1rem;
    }
}