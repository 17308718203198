@import "../../custom.scss";

.company-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    h2 {
        color: #fff !important;
        padding-bottom: 2rem;
    }
    background-color: $secondary;
    min-height: 50vh;
    p {
        color: #CCD3D9;
    }
    .image-wrapper {
        max-height: 15rem !important;
        overflow: hidden;
    }
    .image.is-5by4 {
        height: 100%;
        padding-top: 80%;
        position: relative;
        img {
            width: 100%;
            height: auto;
            position: absolute;
            bottom: 0;
            top: -20%;
            left: 0;
            right: 0;
        }
    }
}