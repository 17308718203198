@import "../../custom.scss";


.search {
    min-height: 100vh;
    .empty-search {
        text-align: center;
        width: 100%;
    }
    .search-grid {
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .category-container {
            transition: background-color .5s ease, margin .2s ease, border-radius 1s ease;
            padding: 1rem;
            &:hover {
                margin-top: -1rem;
                background-color: darken(#fff, 5);
                border-radius: 10px;
                .card {
                    background-color: darken(#fff, 5);
                }
            }
            a {
                text-decoration: none;
                color: #000;
            }
            width: 25%;
            padding: 1rem;
        }
    }
}


@include media-breakpoint-down(sm) {
    .search {
        .search-grid {
            .category-container {
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .search {
        .search-grid {
            .category-container {
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .search {
        .search-grid {
            .category-container {
                width: 33.33%;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .search {
        .search-grid {
            .category-container {
                width: 25%;
            }
        }
    }
}
