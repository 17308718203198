@import '../../custom.scss';

.square-banner {
    position: relative;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    margin: 0 7.5px 15px;
    overflow: hidden;
    margin-right: 3rem;
    margin-left: 3rem;
    h3 {
      color: #fff !important;
      font-family: Roboto !important;
    }
    &:hover {
      cursor: pointer;
  
      & .background-image {
        transform: scale(1.1);
        transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      }
  
      & .content {
        opacity: 0.9;
      }
    }
  
    &.large {
      height: 380px;
    }
  
    .background-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);

    }
    &.name-top {
      
    }
    .content {
      &.name-overlay {
        height: 100%;
        width: 100%;
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;  
        justify-content: center;
        border-radius: 5px 5px 0 0;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
        position: absolute;
        h3.name {
          font-size: 90px;
          color: #fff;
          font-family: 'Pacifico' !important;
          font-weight: 500;
          text-align: center;
        }
      }
      &.name-top {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(3, 18, 26, 0) 50%, rgba(3, 18, 26, .5) 100%);
        letter-spacing: 1.5px;
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        padding: .5rem;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }