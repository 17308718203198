@import '../../custom.scss';
.customer-form {
    .DateInput {
        width: 100%;
        border-bottom: 1px solid lightgray;
    }
    label {
        color: rgba(0, 0, 0, .8);
        padding-left: 10px;
    }
    .group {
        .form-input {
            color:#000;
            background-color: #fff;
            border-bottom: 1px solid lightgrey;
            &:focus {
                ~ .form-input-label {
                    color: black !important;
                }
            }
        }
        .form-input-label {
            color: #a6a6a6;
        }
    }
    .checkbox-wrapper {
      display: flex;
      justify-content: flex-start;
      label {
        font-size: 0.9rem;
        font-weight: 400 !important;
        letter-spacing: 0;
      }
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 400 !important;
      .accept {
          color: black;
      }
    }
    .terms {
        padding: 2rem;
        .link {
          font-size: 0.9rem;
          color: rgba(0, 0, 0, .7) !important;
          text-decoration: underline;
          font-weight: bold;
        }
        .terms-box {
          height: 0;
          opacity: 0;
          max-height: 20rem;
          &.visible {
              height: 100%;
              opacity: 1;
              padding: 2rem;
              margin-top: 2rem;
              margin-bottom: 2rem;
          }
          transition: opacity 1s ease, height 1s ease, margin 1s ease, padding 1s ease;
          background-color: #fff;
          border-color: grey;
          overflow: scroll;
        }
      }
    .finish-wrapper {
    text-align: center;
    .finish {
        height: 48px;
        width: 50%;
        border-radius: 24px;
        background-color: $secondary;
        color: #fff;
        border-color: 1px solid white;
        letter-spacing: 2px;
        font-weight: 500;
        &:hover {
            background-color: $primary;
            transition: .5s ease;
        }
        &:disabled {
            background-color: #5f6c77;
        }
    }
    p {
        margin-top: 1rem;
        color: rgb(245, 170, 170);
    }
    }
    .switch-container {
        width: 100%;
    }
    .switch-div {
        width: 100%;
        height: 4rem;
        .input-group-append, .input-group-prepend {
            width: 50%;
            height: 50%;
        }
      }
      .switch-span {
        font-size: 1.5rem;
        font-style: italic;
      }
      .switch-button {
        width: 100%;
        border-radius: 2rem;
        color: #a3b3c0;
        padding: 1em 2em 2.5em;
        letter-spacing: 2px;
        font-weight: 500;
        border: 1px solid #a3b3c0;
    }
    
    .switch-button.selected {
        background-color: $secondary;
        color: white;
        letter-spacing: 2px;
        font-weight: 500;
    }
    
    .switch-labels {
        .switch-label label {
            width: 80%;
            color: #a3b3c0;
        }
    }
}