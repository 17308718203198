.language-change {
    padding-top: 0 !important;
    .language-header {
        padding: 1rem;
        border-bottom: 1px solid #eee;
        background-color: none !important;
        box-shadow: none;
        margin-bottom: 1rem;
    }
    padding: 1rem;
    border: none !important;
    .currency-wrapper {
        cursor: pointer;
        padding: 1rem 2rem;
        border-radius: 10px;
        margin-top: .5rem;
        .title {
            font-size: 1rem;
            color: black;
        }
        .subtitle {
            font-size: .8rem;
            color: grey;
        }
        &.selected {
            border: .5px solid #ccc;
        }
        transition: background-color .5s ease;
        &:hover {
            background-color: #eee;
            transition: background-color .5s ease;
        }
    }
    a {
        color: #000;
    }
	.row {
		width: 100%;
	}
	.content > p {	
		white-space: pre-wrap;
		text-align: left;
	}
	.image-box {
		height: auto;
		max-width: 100%;
	}
	.price_tag {
		font-size: 1rem;
	    color: #273740;
	    font-weight: 700;
	}
	h1.title {
		font-size: 1rem;
	    color: #5D6E77;
	    font-weight: 700;
	}
}
