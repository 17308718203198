@import "../../custom.scss";

.CouponCard {
    h1.title {
        font-size: 22px;
        font-weight: 700 !important;
    }
    .content {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    input {
        text-transform: uppercase;
        text-align: center;
        border-color: rgba(112,112,112,.5) !important;
    }
    .columns .is-8 {
        text-align: left;
    }
    .columns .is-4 {
        text-align: right;
    }
    .applied {
        color: #00AAD9;
        font-size: .9rem !important;
    }
    h4.title {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    h4.title + p {
        font-size: 1rem;
        font-weight: 400;
    }
    .description {
        white-space: pre-line;
        text-align: left;
        line-height: 1.5rem;
    }
    .apply-coupon {
        font-weight: 700;
        border-radius: 2px;
        color: #FFFFFF;
        width: 100%;
        background-color: $primary;
        height: 100%;
        svg {
            margin-right: 4px;
        }
    }

    .apply-coupon:hover {
        color: #FFFFFF;
    }

    .apply-coupon:active {
        color: #FFFFFF;
    }

  .error {
    svg {
      margin-top: .5rem;
      float: right;
      margin-right: 1rem !important;
    }
    width: 100%;
    height: auto;
    font-size: 1rem;
    text-align: left;
    border-color: rgba(50, 131, 255, .24);
    background-color: #F7FDFF;
    color: #0049B9;
    white-space: normal;
    margin-top: 1rem;
  }


  .extra_option {
    text-align: left;
    border-radius: 3px;
    background-color: #F8F8F8;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    h1.title {
      font-size: 1rem;
      color: #5D6E77;
      font-weight: 700;
    }
    h1.title:not(:last-child) {
      margin-bottom: .5rem;
    }
   .image-box {
      width: 100%;
      margin-left: 1rem;
    }
    .price_tag {
      font-size: 1.2rem;
      color: #DB2C2E;
      font-weight: 700;
      text-align: center;
    }
    .value {
      color: #273740;
      font-size: 1.1rem;
      font-weight: bold;
      margin-top: 0;
      &.discounted {
        text-decoration: line-through;
        color: #BCBCBC;
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }
  .return, .return:hover {
    svg {
        margin-right: 5px !important;
    }
    text-align: left;
    font-size: .9rem;
    margin-top: 1.3rem;
    color: $primary;
    font-weight: 700;
  }
}
