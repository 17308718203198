@import '../../custom.scss';

.control-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        cursor: pointer;
    }
}



@include media-breakpoint-down(sm) {
    .sign-in {
      width: 80vw;
      .form-box {
          max-height: 20rem;
          overflow-y: scroll;
      }
    }
  }
  
  @include media-breakpoint-up(sm) {
    .sign-in {
      width: 30vw;
    }
  }