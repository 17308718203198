@import '../../custom.scss';

.review-and-pay {
    background: rgb(38,80,119);
    padding-top: 5rem;
    background: linear-gradient(180deg, rgba(38,80,119,1) 0%, rgba(24,61,94,0.8925945378151261) 48%, rgba(38,80,119,1) 100%);    padding-bottom: 10rem;
    .payment-amount {
        background-color: #273740;
        color: #ffffff;
        border-radius: 4px;
        padding-left: .5rem;
        padding-right: .5rem;
        font-weight: 700;
    }
    .line_column{
        padding: 0;
        .line {
            height: 1px;
            width: 100%;
            background-color: #5D6E77;
        }
    }
    text-align: center;
    background-color: $primary;
    .clipboard {
        padding-top: 4rem;
        border-radius: 1rem;
        border: 1px solid #fff;
    }
    .category-container {
        width: 80%;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 5rem;
        overflow: hidden;
        box-shadow: black;
        .image-container {
            height: 100%;
            width: 100%;
            img {
                width: 100%;
            }
        }
        h1.title {
            color: $primary !important;
            display: inline-block;
        }
        h2.subtitle {
            display: inline-block;
            margin-left: .5rem;
            color: grey;
        }
        .category-details {
            padding: 2rem;
            text-align: left;
        }
        .features-list {
            display: flex;
            justify-content: flex-start;
            .feature {
                svg {
                    margin-right: .5rem;
                }
                margin-right: 2rem;
            }
        }
    }
	h1.title {
		color: #fff !important;
	}
	.home-wrapper {
		margin-top: 1rem;
	}
	p.email-calling {
		font-size: 14px;
		color: rgb(129, 181, 209);
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
	.florida-home {
		margin: 0 auto;
		padding: 1.8rem;
		width: 20%;
		text-align: left;
		justify-content: end;
		border: 0;
		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
		img {
			width: 4rem;
			height: auto;
		}
		p {
			color: #992730;
			font-weight: 700;
		}
	}
	.content {
		width: 50%;
		margin: 0 auto;
		p {
			font-size: .9rem;
		}
		a.payment-link {
			text-transform: uppercase;
			margin-top: 2rem;
			text-align: center;
			font-weight: 700;
			color: rgb(157, 209, 233) !important;
		}
    }
    .main-content {
            p {
                color: rgba(255,255,255, .7) !important;
            }
    }
	.main-content > svg {
		color: white;
		font-size: 4rem;
		path {
			stroke: #D3D5D8;
			stroke-width: 30;
		}
		margin-top: .6rem;
		margin-bottom: .6rem;
	}
	.box {
		&.receipt {
            border-left: 1px solid white;
            border-right: 1px solid white;
			margin: 0 auto;
            width: 50%;
            padding: 1rem;
			text-align: center;	
			.price_column {
                padding-left: 0.75rem;
                text-align: right;
			}
			h2.title {
				font-size: 1rem;
				font-weight: 900;
				color: #fff;
				margin-bottom: .7rem;
				margin-top: 1rem;
			}
			h3.subtitle {
				font-size: 1rem;
				color: #eeee;
				font-weight: 700;
				margin-bottom: .2rem;
            }
            p {
                color: rgba(255, 255, 255, 0.7);
            }
			.payment-slot {
				background-color: #F5F5F5;
				border-radius: 4px;
				padding: .5rem 1rem .5rem 1rem;
				
			}
			.client {
				padding-bottom: 4rem;
			}
			.small {
				font-size: 12px;
            }
            .extras {
                .extra_tag {
                    text-align: left;
                }
                text-align: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                .row {
                    color:  rgba(255, 255, 255, 0.7);
                    margin-top: 1rem;
                    align-items: center;
                    justify-content: center;
                }
            }
		}
	}
}

@include media-breakpoint-down(sm) {
	.review-and-pay {
		.box {
			&.receipt {
				width: 80%;
			}
		}
		.category-container {
			width: 100%;
		}
	}
}
