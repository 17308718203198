@import '../../custom.scss';

.content-with-image {
    .content-container {
        height: 100%;
        width: 100%;
        display: flex;
        .text-content {
            width: 50%;
            display: flex;
            align-items: center;
            padding: 1rem 2rem;
            font-size: 1.2rem;
        }
        .extra-content {
            width: 50%;
            min-width: 50%;
            display: flex;
            align-items: center;
            padding: 1rem 2rem;
            .img-container {
                padding: 3rem;
            }
        }
    }
    
    &.right {
        background-color: $main-background-color;
    }
    &.left {
        background: linear-gradient(to bottom, $secondary 0%, $primary 100%);
        color: #fff;
    }
    &.top {
        flex-direction: row;
        .content-container {
            .text-content {
                justify-content: center;
            }
            .extra-content {
                justify-content: center;
            }
        }
    }
}


@include media-breakpoint-down(sm) {
    .content-with-image {
        min-height: 100vh;
        height: none;
        .content-container {
            flex-direction: column;
            .text-content {
                width: 100%;
            }
            .extra-content {
                width: 100%;
            }
        }
    }
  }

@include media-breakpoint-up(sm) {
    .content-with-image {
        height: 50vh;
        .content-container {
            padding: 1rem 2rem;
            flex-direction: column;
            .text-content {
                width: 100%;
            }
            .extra-content {
                width: 100%;
            }
        }
    }
    &.right {
        .content-container {
            flex-direction: row-reverse;
        }
    }
    &.left {
        .content-container {
            flex-direction: row;
        }
    }
  }