@import "../../custom.scss";

.faq {
    background-color: $main-background-color;
    .heading {
        text-align: center;
        background-color: $primary;
        background-blend-mode: multiply;
        input.faq-search {
            border: 0;
            width: 50%;
            height: 3rem;
            border-radius: 5rem;
            padding: 1.5rem;
            font-size: 1.5rem;
            color: grey;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-weight: 400;
            max-width: 50rem;
        }
        color: #fff;
        border-bottom-left-radius: 3rem;
        border-bottom-right-radius: 3rem;
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: 0 20%;
    }
    .faq-content {
        margin: 0 auto;
        .container {
            max-width: $width-container-size;
            padding: 2rem;
            h1 {
                margin-bottom: 3rem;
                font-size: 2rem;
            }
        }
    }
    .values {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}