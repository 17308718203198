@import "../../custom.scss";

.protections-extras {
    h1.title {
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        border-bottom: 3px solid rgba(0, 0, 0, .3);
    }
    >.container-fluid {
        padding: 0 5rem;
    }
    .section-wrapper {
        border: 1px solid #fff;
        border-radius: 5rem;
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,1);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,1);
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,1);
        padding: 5rem;
    }
}
.coupon-call {
    border-radius: 0;
    width: 100%;
    height: 55px;
    margin-top: 1.3rem;
    font-size: 1rem;
    border: 2px solid $primary;
    border-radius: 3px;
    color: $primary;
    font-weight: 700;
    &:hover {
        background-color: $primary;
    }
  }
  .buttonWrapper {
    width: 100%;
    margin-bottom: 1rem;
  }
  .applied-coupon {
    svg {
      float: right;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1rem !important;
    text-align: left !important;
    border-color: rgba(50, 131, 255, .24) !important;
    background-color: #F7FDFF !important;
    color: #0049B9 !important;
    white-space: normal;
    margin-top: 1rem;
  }

@include media-breakpoint-down(sm) {
  .protections-extras {
    .container-fluid {
      padding: 0;
      .section-wrapper {
        padding: 2rem;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
}