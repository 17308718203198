@import "../../custom.scss";

.location-section {
    background-color: $secondary;
    padding-top: 2rem;
    padding-bottom: 2rem;
    min-height: 600px;
    color: #CCD3D9;
    h3 {
        color: #fff !important;
    }
    p {
    }
    .image.is-5by4 {
        padding-top: 80%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
        }
    }
}