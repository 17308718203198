@import "../../custom.scss";
.header {
  background-image: linear-gradient(to top, rgba(3, 18, 26, 0), rgba(3, 18, 26, .7));
  position: relative;
  .navbar-brand {
    width: 15rem;
    height: auto;
    svg {
      height: 100%;
      width: 100%;
      path: {
        fill: #fff !important;
      }
    }
  }
  .content-delimiter {
    display: flex;
    width: 100%;
    margin: 0 auto;
    max-width: $width-container-size;
  }
  z-index: 2;
  &.homepage {
    &.desktop {
      position: absolute !important;
      max-height: 10rem;
      .login-container {
        width: 5rem;
      }
      
      .image-container {
        width: 3.5rem;
      }
    }
    &.mobile {
      position: fixed;
    }
  }
  &.navbar-light {
    border-bottom: 1px solid lightgrey;
    .navbar-brand {
      .nav-link {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  color: #fff;
  width: 100%;
  .nav-link {
    color: #fff;
  }
  a.whatsapp {
    color: #fff;
  }
  .navbar-nav {
    align-items: center;
  }
  &.mobile {
    .image-container {
      height: 100%;
      width: 20%;
    }
    .navbar-nav {
      justify-content: space-between;
    }
  }
}
@media (max-width: 992px) {
  .header {
    &.mobile {
      display: flex !important;
      bottom: 0;
      background-color: $primary;
      .flags {
        margin-left: 1rem;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
    .header {
      &.mobile {
        bottom: 0;
        background-color: $primary;
        display: flex;

      }
    }
    .search-header {
      margin: 0 auto;
      padding: 0 !important;
      height: 0;
      visibility: hidden !important;
      top: 0;
      left: 0;
      .close-search {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        transition: height 1s ease, visibility 1s ease;
        background-color: transparent;
        border-color: transparent;
      }
      &.closed {
        height: 0;
        
      }
      &.open {
        -webkit-transition: height 1s ease;
        -moz-transition: height 1s ease;
        -o-transition: height 1s ease;
        transition: height 1s ease, visibility 1s ease;
        position: fixed;
        width: 100%;
        z-index: 3;
        visibility: visible !important;
        display: flex !important;
        height: 20rem;
      }
    }
}

@include media-breakpoint-up(sm) {
  .header {
    .params-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      .search-params {
        border-radius: 5rem;
        padding: 1rem;
      }
    }
    &.desktop {
      top: 0;
    }
  }
  .search-header {
    margin: 0 auto;
    padding: 0 !important;
    height: 0;
    visibility: hidden !important;
    top: 0;
    left: 0;
    .close-search {
      position: absolute;
      top: 1rem;
      right: 1rem;
      transition: height 1s ease, visibility 1s ease;
      background-color: transparent;
      border-color: transparent;
    }
    &.closed {
      height: 0;
      
    }
    &.open {
      -webkit-transition: height 1s ease;
      -moz-transition: height 1s ease;
      -o-transition: height 1s ease;
      transition: height 1s ease, visibility 1s ease;
      position: fixed;
      width: 100%;
      z-index: 3;
      visibility: visible !important;
      display: flex !important;
      height: 20rem;
    }
  }
}