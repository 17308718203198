@import "../../custom.scss";

.cities {
    background-color: #fff;
    padding: 2rem 0;
}


@include media-breakpoint-down(sm) {
  }
  
  @include media-breakpoint-up(sm) {

    }
  @include media-breakpoint-up(md) {
    max-height: 50vh;
  }