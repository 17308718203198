@import "../../custom.scss";

$title-color: #000;
$subtitle-color: rgba(0, 0, 0, .6);

.extras {
    position: relative;
    .category-banner {
        .subtitle {
            color: grey;
        }
        height: 35vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-items: center;
        overflow: hidden;
        .category-info {
            position: absolute;
            left: 2rem;
            top: 2rem;
        }
        .img-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            img {
                width: 45%;
                margin-right: -60px;
            }
        }
    }
    .protections-extras {
        ul {
            list-style: none;
        }
        .protections-wrapper {
          padding: 0 5rem 0 0;
          ul {
            margin-bottom: 2rem;
          }
        }
        padding-top: 5rem;
        min-height: 100vh;
        background-color: $primary;
    }
    text-align: left;
    div.protections {
        display: inline-block;
        font-size: 1rem;
        font-weight: 700;
        color: $title-color;
        text-transform: lowercase;
        svg {
        font-size: 1rem;
        color: $title-color;
        margin-right: 1.5rem;
        }
    }
    li.protectionsWrapper {
        display: flex;
        .list-icon {
            width: 1.125rem;
            margin-right: .5rem;
            flex-shrink: 0;
            span {
                display: inline-block;
            }
        }
        svg {
            font-size: 1rem;
            color: lighten($primary, 20);
        } 
    }
    .title {
        color: $title-color;
        margin-bottom: 0;
    }
    .subtitle {
        color: $subtitle-color;
        font-size: 1rem;
    }
    
  .extra_option {
    background-color: #fff;
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 1rem;
    width: 100%;
    background-color: #2A4865;
    border: 1px solid #f7f7f7;
    border-radius: 10px;
    .heading {
      margin-bottom: 1.3rem;
      h1 {
        color: #fff;
        margin-bottom: 0 !important;
        padding-bottom: 0;
      }
      .details {
        margin-bottom: 0;
        color: rgba(255, 255, 255, .9);
        text-decoration: underline;
      }
    }
    .extra_details {

    }
    h1.title {
      font-size: .8rem;
      color: #fff;
      font-weight: 700;
      border: 0;
    }
    .see-more {
      color: #0049B9 !important;
      font-size: .9rem;
      text-decoration: underline;
    }
   .image-box {
      img {
          width: 100%;
      }
    }
    .price_tag {
      font-size: 1rem;
      color: rgba(255, 255, 255, .6);
      font-weight: 700;
      padding-left: 0;
      margin-bottom: 1.1rem;
    }
  }

  .checkbox {
    width: 1.5rem;
    border: 2px solid #ccc;
    height: 1.5rem;
    position: relative;
    cursor: pointer;
    &.is-active {
      font-size: 1rem;
      color: #fff !important;
      background-color: #fff;
      border-color: #fff;
    }
    span.check {
      color: $primary;
      position: absolute;
      top: 0px;
      right: 2px !important;
    }
  }

  .quantity_wrapper {
    display: flex;
    width: 111px;
    max-height: 40px;
    border: 1px solid #C5CDF4;
    border-radius: 4px;
    padding-left: 0;
    overflow: hidden;
    .quantity_controllers  {
      display: flex;
      position: relative;
      flex-direction: row;
      width: 67%;
      border-color: #C5CDF4;
      background-color:#EFEFEF;
      .divider {
        height: 24px;
        width: 1px;
        position: absolute;
        background-color: #C5CDF4;
        top: 7px; 
        left: 34px;
      }
    }
    button {
      width: 33%;
      margin-left: .4rem;
      margin-right: .4rem;
      font-size: 1rem;
      border: 0;
      background-color: transparent;
      svg path{
        fill: #2F80ED;
      }
      padding: 0;
      &:hover {
        svg path {
          fill: lighten(#2F80ED, 10%);
        }
      }
      &:active {
        svg path {
          fill: darken(#2F80ED, 10%);
        }
      }
    }
    button.disabled {
      svg path {
          fill: lighten(#2F80ED, 30%);
        }
    }
    span.extra_quantity {
      text-align: center;
      font-weight: 500;
      background-color: #fff;
      color: $title-color;
      padding: 10px;
      width: 33.3%;
      font-size: 14px;
      border-right: 1px solid #C5CDF4;
    }
  }

  .dates {
    margin-bottom: 2rem;
    .title {
      margin-bottom: 2.5rem;
    }
    .subtitle {
      margin-top: 1rem;
      margin-bottom: .5rem;
      font-weight: 700;
      font-size: 1rem;
      color: $subtitle-color !important;
    }
    p {
      font-size: .9rem;
      color: $subtitle-color !important;
    } 
  }

  .category {
    margin-bottom: 2rem;
    .car_title {
      font-size: 1rem;
      color: #273740;
      font-weight: bold;
    }
    .image-box {
      width: 100%;
      height: auto;
      margin-right: 0;
    }
  }
  .value {
    color: $title-color;
    font-size: 1.5rem;
    font-weight: bold;
    &.discounted {
      text-decoration: line-through;
      color: #898989;
      font-size: 1rem;
    }
  }
  .extras {
    color: $subtitle-color;
    font-size: 14px;
    span.extra_price {
      font-weight: 700;
    }
    .extra_tag {
      font-weight: 400;
    }
    .price_column {
    }
  }
  .btn.proceed {
    width: 100%;
    letter-spacing: 2px;
    font-weight: 700;
    .icon {
      padding-left: 2rem;
    }
  }
}


@include media-breakpoint-down(sm) {
  .extras {
    .extra_details {
      justify-content: center;
      align-content: center;
      .price_tag {
        justify-content: center;
      }
      .quantity_tag {
        justify-content: center;
      }
    }
    .protections-extras {
      .protections-wrapper {
        padding: 0;
      }
    }
  }
}