@import "../../custom.scss";

.faq-option {
                margin-bottom: 2rem;
                width: 100%;
                .faq-question {
                    border-left: 3px solid $primary;
                    display: flex;
                    width: 100%;
                    height: 5rem;
                    background-color: darken($main-background-color, 3);
                    flex-direction: row;
                    padding: 1rem;
                    align-items: center;
                    cursor: pointer;
                    .faq-letter {
                        display: flex;
                        width: 3rem;
                        font-size: 2rem;
                        color: $primary;
                    }
                }
                .faq-answer {
                    display: flex;
                    width: 100%;
                    border-left: 3px solid rgba(97, 84, 84, 0.5);
                    max-height: 0;
                    padding: 0;
                    color: transparent;
                    background-color: darken($main-background-color, 1);
                    flex-direction: row;
                    align-items: center;
                    transition: padding 1s ease, height 1s ease;
                    overflow: hidden;
                    &.open {
                        min-height: 5rem;
                        max-height: none;
                        padding: 1rem;
                        color: rgba(0, 0, 0, .5);
                    }
                    .faq-letter {
                        align-self: flex-start;
                        display: flex;
                        width: 3rem;
                        font-size: 2rem;
                    }
                }
            }