.authentication {
    height: 100vh;
    display: flex;
    z-index: 10;
    align-items: center;
    justify-content: space-around;
}
.bg-image {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: center !important;
    filter: blur(20px);
}