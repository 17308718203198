.service-card {
    min-height: 10rem;
    margin-bottom: 1rem;
    h5 {
    }
    height: 20rem;
    .icon-container {
        text-align: center;
        height:50%;
        width: auto;
        svg {
            height: 100%;
            width: auto;
        }
    }
}