@import '../../custom.scss';

.homepage {
  section {
    h2 {
      position: relative;
      &.text-center {
        font-family: 'Bebas Neue';
        font-weight: normal;
        font-size: 3rem;
        color: $gray2;
        letter-spacing: 0.04rem;
        &:before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 5px;
          background-color: rgb(72, 166, 243);
          margin-right: 1rem;
          vertical-align: middle;
        }
        &:after {
          content: '';
          display: inline-block;
          width: 10px;
          height: 5px;
          background-color: rgb(72, 166, 243);
          margin-left: 1rem;
          vertical-align: middle;
        }
      }
    }
    h3 {
      font-family: 'RobotoCondensed SC';
      color: $gray2;
    }
  }
}
