$sub-color: grey;
$main-color: black;

@mixin shrinkLabel {
  top: -1.5rem;
  font-size: 1rem;
  color: $main-color;
}

.group {
  position: relative;
  margin: 0px 0;

  .SingleDatePicker {
    background: none;
    background-color: white;
    color: $sub-color;
    font-size: 18px;
    display: block;
    width: 100%;
    margin: 25px 0;

    &:focus-within {
      outline: none;
    }

    &:focus-within ~ .form-input-label {
      @include shrinkLabel();
    }
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  .form-input-label {
    color: $sub-color;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
    &.shrink {
      @include shrinkLabel();
    }
  }
}
