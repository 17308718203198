@import "../../custom.scss";

.search-box {
    h1 {
        font-family: 'Roboto SC' !important;
        font-size: 2rem;
    }
    &.landing {
        z-index: 1;
    }
}

@include media-breakpoint-down(sm) {
    .search-box {
        &.landing {
        }
    }
}

@include media-breakpoint-up(md) {
    .search-box {
        &.landing {
            position: absolute !important;
            top: 25vh;
            left: 10%;
            .card {
                max-width: 400px;
            }
        }
    }
}