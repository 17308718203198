@import "../../custom.scss";

.landing {
    .cycling-image {
        width: 100%;
        background-size: cover !important;
        background-position: center !important;
    }
    .slider-container {
        width: 100%;
        height: 100%;
        margin: auto; 
        .each-slide {
            height: 100vh;
        }
    }
      
      h3 {
        text-align: center; }
      .each-slide {
          height: 100%;
      }
      .each-slide > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 100%;
      }
      
      .each-slide span {
        padding: 20px;
        font-size: 20px;
        background: #efefef;
        text-align: center;
      }
      
      .each-fade {
        display: flex;
      }
      
      .each-fade .image-container {
        width: 75%;
        overflow: hidden;
      }
      
      .each-fade .image-container img {
        width: 100%;
      }
      
      .each-fade h2 {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background: #adceed;
      }      
}

@include media-breakpoint-down(sm) {
    .landing {
        .slider-container {
            height: 50vh;
            .each-slide {
                height: 50vh;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .landing {
        .slider-container {
        height: 50vh;
        .each-slide {
            height: 50vh;
        }
        }
    }
}

@include media-breakpoint-up(md) {
    .landing {
        .slider-container {
        height: 100vh;
        .each-slide {
            height: 100vh;
        }
        }
    }
}