.services-section {
    background-color: #F1F3F7;
    padding: 2rem 0;
    min-height: 50vh;
    li.advantages {
        font-weight: 700;
        svg {
            color: #40A0DD;
            font-size: 1.3rem;
        }
    }
    .image.is-5by4 {
        padding-top: 50%;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        vertical-align: middle;
        img {
            width: 100%;
            height: auto;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
        }
    }
}