$theme-colors: (
    "primary": #265077,
    "secondary": #022140
);
$primary: #265077;
$secondary: #022140;
$gray2: #4F4F4F;
$main-background-color: #f3f5f8;

$width-container-size: 1500px;
@import "~bootstrap/scss/bootstrap.scss";
@import url("//fonts.googleapis.com/css?family=Ubuntu:300,400,500,700,800");
@import url("//fonts.googleapis.com/css?family=Roboto:300,400,500,700,800");
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,800');
@import url('https://fonts.googleapis.com/css?family=Pacifico:300,400,500,700,800');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
    font-family: 'Roboto SC';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'),
         url('./assets/fonts/roboto-medium-webfont.woff2') format('woff2')
  }


@font-face {
    font-family: 'RobotoCondensed SC';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto'),
         url('./assets/fonts/robotocondensed-bold-webfont.woff2') format('woff2')
  }

.App {
    background-color: $main-background-color;
    font-family: 'Roboto';
    
    h1 {
        font-family: 'Montserrat';
    }
    
    .pattern {
        background-repeat: repeat;
    }
    
    .content-container {
        margin: 0 auto;
        max-width: $width-container-size;
    }
}