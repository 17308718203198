@import '../../custom.scss';

.nav-link {
    &:after {
        display:block;
        content: '';
        border-bottom: solid 3px #fff;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        transform-origin:100% 50%
    }
    &:hover {
        &:after {
            transform: scaleX(1);
            transform-origin:0 50%;
        }
    }
}