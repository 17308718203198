@import '../../custom.scss';

.sign-in {
  display: flex;
  flex-direction: column;
}

.control-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    cursor: pointer;
  }
}


@include media-breakpoint-down(sm) {
  .sign-in {
    width: 80vw;
  }
}

@include media-breakpoint-up(sm) {
  .sign-in {
    width: 30vw;
  }
}