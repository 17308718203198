@import "../../custom.scss";

.vehicles {
    padding: 2rem 0;
    background-color: #fff;
    .carousel {
        .slider-container {
            .square-banner {
                box-shadow: 0px 2px 3px rgba(0, 0, 0, .2);
            }
        }
    }
}


@include media-breakpoint-down(sm) {
  }
  
  @include media-breakpoint-up(sm) {

    }
  @include media-breakpoint-up(md) {
    max-height: 50vh;
  }