.instagram-card {
    margin-left: 3rem;
    margin-right: 3rem;
    .card {
        border-radius: 8px;
        border: 0;
        box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        margin: 0 auto;
        max-width: 25rem;
        a {
            text-transform: none;
        }
        .media {
            width: 100%;
            align-items: flex-start;
            display: flex;
            text-align: left;
            font-size: 1.6rem;

            .media-left {
                svg {
                    margin-right: 1rem;
                }
                flex-basis: auto;
                flex-grow: 0;
                flex-shrink: 0;
                margin-right: 1rem;
            }
            .media-content {
                flex-basis: auto;
                flex-grow: 1;
                flex-shrink: 1;
                text-align: left;
            }
            .media-right {
                flex-basis: auto;
                flex-grow: 0;
                flex-shrink: 0;
                margin-left: 1rem;
            }
        }
        .image {
            margin-top: 0rem;
            display: block;
            position: relative;
            padding-top: 100%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                position: absolute;
                bottom: 0;
                left: 0;
                top: 0;
                right: 0;
            }
        }
        .content-wrapper {
            align-items: stretch;
            display: flex;
            font-size: .9rem;
            .card-content {
                padding: 1.5rem;
                padding-top: .9rem;
                width: 100%;
                a {
                    font-weight: 700;
                    color: #359CED;
                }
                width: 100%;
            }
            .description {
              min-height: 5rem;
              max-height: 5rem;
              overflow: hidden;
          }
          .description:hover {
              overflow-y: scroll;
          }
        }
    }
}